import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FullPageCard } from '../components/misc/full-page-card'
import { useAuth } from '../supabase'
import { ActionButton } from '../components/form/action-button'
import { UserInfoContext } from './root-page'

export const ReportPage = () => {
  const { supabase } = useAuth()
  const { userInfo } = useContext(UserInfoContext)
  const [isLoading, setIsLoading] = useState(false)
  const [frameworks, setFrameworks] = useState([]) // To hold the frameworks

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      reportType: 'compliance',
      status: 'All',
      fileFormat: 'XLSX',
      addDescription: false,
      addNote: false,
      addTasks: false,
      addRelatedCompliance: false,
      addRelatedRisks: false,
      addRelatedPolicies: false,
      addRelatedControls: false,
      selectedFramework: '' // New field for selected framework
    }
  });

  // Watch the reportType field to conditionally show the framework selection
  const reportType = watch('reportType')

  useEffect(() => {
    // Fetch frameworks from supabase when component mounts
    const fetchFrameworks = async () => {
      const { data, error } = await supabase
        .from('framework')
        .select('id, name, fk_project_id')
        .eq('fk_project_id', userInfo.project.id)

      if (!error) {
        setFrameworks(data)
      } else {
        console.error('Error fetching frameworks', error)
      }
    }

    fetchFrameworks()
  }, [supabase])

  const onSubmit = async (formData) => {
    setIsLoading(true);

    try {
      const publicKey = 'da2-zp37ssc2lvgijhtu2pyrklnlay';
      const graphqlEndpoint = 'https://rhckeskesrfnjoaszzrcbneale.appsync-api.eu-west-3.amazonaws.com/graphql';
      //TODO project_id shouldn't be sent this way
      const framework_id = formData.reportType === 'control' ? userInfo.project.id : formData.selectedFramework ;
      const report_type = formData.reportType;

      const query = `
        query MyQuery($framework_id: String!, $report_type: String!) {
          getReport(framework_id: $framework_id, report_type: $report_type) {
            error
            success
            url
          }
        }
      `;
  
      // Call the GraphQL Endpoint
      const response = await fetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': publicKey,
        },
        body: JSON.stringify({
          query,
          variables: { framework_id: framework_id, report_type: report_type },
        }),
      });
  
      const json = await response.json();
  
      // Check for Errors
      if (json.errors || !json.data?.getReport?.success) {
        console.error('GraphQL Error:', json.errors || json.data?.getReport?.error);
        setIsLoading(false);
        return;
      }

      const fileUrl = json.data.getReport.url;
      window.open(fileUrl, '_blank');
    } catch (error) {
      jToaster.error("An error occurred during the report generation");
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FullPageCard addClassname='max-w-xl'>
      <div className="text-center mb-8 border-b border-gray-200 pb-4">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-neutral-200">
          Reports
        </h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
          <div className="flex flex-col gap-3">
            <select
              {...register('reportType', { required: 'Please select a report type.' })}
              className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
            >
              <option value="compliance">Compliance Report</option>
              <option disabled value="Risks Report">Risk Report</option>
              <option disabled value="Policies Report">Policy Report</option>
              <option value="control">Control Report</option>
            </select>
            {errors.reportType && <p className="text-red-600 text-sm">{errors.reportType.message}</p>}
          </div>

          {/* Conditionally show framework selection if Compliance Report is selected */}
          {reportType === 'compliance' && (
            <div className="flex flex-col gap-3 my-4">
              <select
                {...register('selectedFramework', { required: 'Please select a framework.' })}
                className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              >
                <option value="">Select a framework</option>
                {frameworks.map((fw) => (
                  <option key={fw.id} value={fw.id}>
                    {fw.name}
                  </option>
                ))}
              </select>
              {errors.selectedFramework && <p className="text-red-600 text-sm">{errors.selectedFramework.message}</p>}
            </div>
          )}

          <div className="flex flex-col sm:flex-row gap-3 my-4">
            <select disabled {...register('status')} className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600">
              <option value="All">All Items</option>
              <option value="Only Active">Active Items Only</option>
            </select>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 my-4">
            <select disabled {...register('fileFormat')} className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600">
              <option value="PDF" disabled>.PDF</option>
              <option value="XLSX">.XLSX</option>
            </select>
          </div>

          <label className="inline-block text-sm font-medium dark:text-white">General Options</label>
          <div className="mt-2 space-y-3">
            <div className="mt-4 flex">
              <input disabled type="checkbox" {...register('addDescription')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-description" />
              <label htmlFor="add-description" className="text-sm text-gray-400 ms-2">Include Description</label>
            </div>
            <div className="mt-4 flex">
              <input disabled type="checkbox" {...register('addNote')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-note" />
              <label htmlFor="add-note" className="text-sm text-gray-400 ms-2">Include Notes</label>
            </div>
            <div className="mt-4 flex">
              <input disabled type="checkbox" {...register('addTasks')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-tasks" />
              <label htmlFor="add-tasks" className="text-sm text-gray-400 ms-2">Include Related Tasks</label>
            </div>
          </div>
        </div>

        <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 ">
          <label className="inline-block text-sm font-medium dark:text-white">Connection Details</label>
          <div className="mt-4 flex">
            <input disabled type="checkbox" {...register('addRelatedCompliance')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-compliance" />
            <label htmlFor="add-compliance" className="text-sm text-gray-400 ms-2">Include Related Compliance</label>
          </div>
          <div className="mt-4 flex">
            <input disabled type="checkbox" {...register('addRelatedRisks')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-risk" />
            <label htmlFor="add-risk" className="text-sm text-gray-400 ms-2">Include Related Risk</label>
          </div>
          <div className="mt-4 flex">
            <input disabled type="checkbox" {...register('addRelatedPolicies')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-policy" />
            <label htmlFor="add-policy" className="text-sm text-gray-400 ms-2">Include Related Policies</label>
          </div>
          <div className="mt-4 flex">
            <input disabled type="checkbox" {...register('addRelatedControls')} className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" id="add-control" />
            <label htmlFor="add-control" className="text-sm text-gray-400 ms-2">Include Related Controls</label>
          </div>
        </div>

        <div className="mt-5 flex justify-end gap-x-2 border-t border-gray-200 pt-4">
          <ActionButton type="submit" loading={isLoading}>
            Download
          </ActionButton>
        </div>
      </form>
    </FullPageCard >
  )
}