import React, { createContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { MainMenu } from '../components/main-menu';
import { useAuth } from '../supabase';
import { JFormatDate } from '../components/misc/columns_def';

export const UserInfoContext = createContext()

export const RootPage = () => {
  const { supabase, session } = useAuth()

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const isLogged = !!session?.user?.id;

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();

    window.location.replace('/auth/login');
  }

  const fetchUserInfo = async () => {
    if (isLogged) {
      try {
        const { data: userData, error } = await supabase
          .from('user')
          .select('*, group(*), project(*)')
          .eq('fk_auth_id', session.user.id)
          .single();
        if (error) throw error;

        const { data: mfaData, error: mfaError } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
        if (mfaError) throw mfaError;

        const { data: customFieldsData, error: errorCustomFields } = await supabase
          .from('custom_field_definitions')
          .select('*')
          .eq('fk_project_id', userData.fk_project_id)

        if (errorCustomFields) throw errorCustomFields;

        const { data: enumData, error: errorEnum } = await supabase
          .from('project_enum')
          .select('*')
          .eq('fk_project_id', userData.fk_project_id)

        if (errorEnum) throw errorEnum;

        const userInfoData = {
          ...userData,
          aal: mfaData.currentLevel,
          enumData: {
            risk_score: enumData
              .filter((e) => e?.enum_name === 'RISK_SCORE')
              .sort((a, b) => a.display_order - b.display_order)
              .map((e) => ({ value: e?.allowed_value, label: e?.allowed_label })),
            risk_probability: enumData
              .filter((e) => e?.enum_name === 'RISK_PROBABILITY')
              .sort((a, b) => a.display_order - b.display_order)
              .map((e) => ({ value: e?.allowed_value, label: e?.allowed_label })),
            risk_impact: enumData
              .filter((e) => e?.enum_name === 'RISK_IMPACT')
              .sort((a, b) => a.display_order - b.display_order)
              .map((e) => ({ value: e?.allowed_value, label: e?.allowed_label })),
            risk_strategy: enumData
              .filter((e) => e?.enum_name === 'RISK_STRATEGY')
              .sort((a, b) => a.display_order - b.display_order)
              .map((e) => ({ value: e?.allowed_value, label: e?.allowed_label })),
            compliance_maturity: enumData
              .filter((e) => e?.enum_name === 'COMPLIANCE_MATURITY')
              .sort((a, b) => a.display_order - b.display_order)
              .map((e) => ({ value: e?.allowed_value, label: e?.allowed_label })),
          },
          customFields: {
            risk: customFieldsData
              .filter((e) => e?.type === 'RISK')
              .sort((a, b) => a.display_order - b.display_order),
            policy: customFieldsData
              .filter((e) => e?.type === 'POLICY')
              .sort((a, b) => a.display_order - b.display_order),
            control: customFieldsData
              .filter((e) => e?.type === 'CONTROL')
              .sort((a, b) => a.display_order - b.display_order),
            compliance: customFieldsData
              .filter((e) => e?.type === 'COMPLIANCE')
              .sort((a, b) => a.display_order - b.display_order),
          }
        }

        setUserInfo(userInfoData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user info: ', error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect( () => {
    if (isLogged) {
      fetchUserInfo();
    } else {
      setIsLoading(false);
    }
  }, [isLogged]);

  if (isLoading) {
    return <></>;
  }

  // Redirect to login if not logged in
  if (!isLogged) {
    const hashAsQuery = window.location.hash.replace('#', '?');

    window.location.replace(`/auth/login${hashAsQuery}`);
    return <></>;
  }

  // Check for password update requirement or MFA verification
  if (userInfo && userInfo.password_updated === false) {
    window.location.replace('/auth/update-password');
    return <></>
  }

  if (userInfo && userInfo.mfa_required && userInfo.aal === 'aal1') {
    window.location.replace(`/auth/mfa-verification/${userInfo.mfa_factor_id}`);
    return <></>
  }

  return (
    <UserInfoContext.Provider value={{ userInfo: userInfo }}>
      <div className='flex flex-col min-h-screen'>
        <MainMenu onSignoutClick={handleSignOut} isSuperUser={userInfo?.type === 'SUPERUSER'} />
        <div className='flex-grow mx-8 mt-4'>
          <div className='flex justify-center  mx-auto lg:ml-72'>
            <Outlet />
          </div>
        </div>
      </div>
    </UserInfoContext.Provider>
  )
}
